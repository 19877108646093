import React from 'react';
import { Card } from '@material-ui/core';
import DealLink from '@/common/DealLink';
import styles from '@/homepage/MerchandisingBanner/MerchandisingBanner.styles';
import ResponsiveImageRender from '@/common/ResponsiveImageRender';
import { useMerchandisingBanner } from '@/homepage/MerchandisingBanner/hooks/useMerchandisingBanner';

const MerchandisingBanner = (): JSX.Element | null => {
  const classes = styles();
  const defaultImage = {
    mobile: '/images/placeholder-image.png',
    desktop: '/images/placeholder-image.png',
    altText: 'loading',
    title: 'loading'
  };

  const { data, error, isLoading } = useMerchandisingBanner();

  if (error || data === null) {
    return null;
  }

  // Use default image when loading
  const image = isLoading
    ? defaultImage
    : {
      mobile: data.mobileImage || defaultImage.mobile,
      desktop: data.desktopImage || defaultImage.desktop,
      altText: data.altText || defaultImage.altText,
      title: data.altText || defaultImage.title
    };

  const link = isLoading
    ? { link: '', linkTitle: '' }
    : { link: data.targetUrl || '', linkTitle: data.altText || '' };

  return (
    <div data-testid="merchandiseBanner" id="merchBanner" className={classes.merchBannerContainer}>
      <DealLink
        tabIndex={0}
        className={classes.merchBannerWrapper}
        testId="merchandiseBannerLink"
        dataAnalyticsCategory="merchandiseBanner"
        dataAnalyticsAction={link.link}
        {...link}
        external={false}
      >
        <Card onClick={() => {}} data-testid="merchandiseBannerCard" className={classes.merchBannerCard}>
          <ResponsiveImageRender
            testId="mobile-picture-source"
            className={classes.merchBanner}
            loading={image.mobile === '' || undefined}
            loadingAnimation="wave"
            {...image}
          />
        </Card>
      </DealLink>
    </div>
  );
};

export default MerchandisingBanner;
