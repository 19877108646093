import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useFetchContentfulDataQuery } from '@/api/reduxToolkit/contentfulData';
import {
  MerchBannerComponentData
} from '@/homepage/MerchandisingBanner/hooks/types';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { userDomainSelectors } from '@/header/userDomainSelectors';

const USER = {
  AUTHENTICATED: "Authenticated" as const,
  UN_AUTHENTICATED: "Unauthenticated" as const,
  LOCALIZED: "Localized" as const,
  UNLOCALIZED: "Unlocalized" as const
};

/**
 * Constructs a GraphQL query for fetching homepage override data
 * @param storeId - The store identifier
 * @param authenticated - The authentication status ("Yes" or "No")
 * @param localized - The localization status ("Yes" or "No")
 * @returns A string containing the GraphQL query
 */
const buildMerchandiseBannerQuery = (
  storeId?: string,
  authenticated?: string,
  localized?: string
): string => {
  const whereClauses: string[] = [];

  // If there is no storeId or user not localized, only add "all stores"
  if (!storeId && localized === USER.UNLOCALIZED) {
    whereClauses.push(`{ stores_contains: "all stores" }`);
  }
  // If storeId exists and user is localized, include both storeId and "all stores"
  else if (storeId) {
    whereClauses.push(`{
      OR: [
        { stores_contains: $storeId }
        { stores_contains: "all stores" }
      ]
    }`);
  }

  // Filter by authentication status
  whereClauses.push(`{
    OR: [
      { authenticated: "Both" }
      { authenticated: $authenticated }
    ]
  }`);

  // Filter by localization status
  whereClauses.push(`{
    OR: [
      { localized: "Both" }
      { localized: $localized }
    ]
  }`);

  // Construct the final filter clause
  const whereFilter = whereClauses.length ? `where: { AND: [ ${whereClauses.join(", ")} ] }` : "";

  return `
    query GetHomepageContent(
      ${storeId ? `$storeId: String,` : ""} 
      $authenticated: String,
      $localized: String
    ) {
      homepageContentCollection(limit: 1) {
        items {
          merchandiseBannersCollection(limit: 1, ${whereFilter}) {
            items {
              component {              
                targetUrl
                altText
                desktopImage {
                  url
                }
                mobileImage {
                 url
                }
              }
            }
          }
        }
      }
    }
  `;
};

export const useMerchandisingBanner = (): { data: MerchBannerComponentData | null; error: FetchBaseQueryError | SerializedError | undefined; isLoading: boolean; isFetching: boolean } => {

  const isLocalized = useSelector(localizationSelectors.isLocalized);
  const isLoggedIn = useSelector(userDomainSelectors.isAuthenticatedUser);
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const storeId = storeDetails?.storeNumber;
  
  const userLocalized = isLocalized ? USER.LOCALIZED : USER.UNLOCALIZED;
  const userAuthenticated = isLoggedIn ? USER.AUTHENTICATED : USER.UN_AUTHENTICATED;

  const HOMEPAGE_MERCHANDISE_BANNER_QUERY = buildMerchandiseBannerQuery(storeId, userAuthenticated, userLocalized);

  const variables: { authenticated: string; localized: string; storeId?: string } = {
    authenticated: userAuthenticated,
    localized: userLocalized
  };

  // Only add storeId if it is defined
  if (storeId) {
    variables.storeId = storeId;
  }

  const { data, error, isLoading, isFetching } = useFetchContentfulDataQuery(
    { query: HOMEPAGE_MERCHANDISE_BANNER_QUERY, variables },
    { refetchOnMountOrArgChange: false, refetchOnReconnect: false }
  );

  return { data: data ?? null, error, isLoading, isFetching };
};